import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { fromEvent, merge, of, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppConnectionService {
  $isOffline = merge(
    of(null),
    fromEvent(window, "online"),
    fromEvent(window, "offline")
  ).pipe(map(() => !navigator.onLine));

  private readonly isHubConnectionLimitExceeded = new Subject<boolean>();
  private readonly isHubUnauthorized = new Subject<boolean>();
  readonly isHubConnectionLimitExceeded$ =
    this.isHubConnectionLimitExceeded.asObservable();
  readonly isHubUnauthorized$ = this.isHubUnauthorized.asObservable();
  private statusToastId: Guid = Guid.createEmpty();

  setStatusToastId(id: Guid) {
    this.statusToastId = id;
  }

  getStatusToastId() {
    return this.statusToastId;
  }

  setHubConnectionLimitExceeded(isExceeded: boolean) {
    this.isHubConnectionLimitExceeded.next(isExceeded);
  }

  setHubUnauthorized(isUnauthorized: boolean) {
    this.isHubUnauthorized.next(isUnauthorized);
  }
}
